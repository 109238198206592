<template>
	<div class="page_wrap">
		<top :bgWhite='true'></top>
		<div class="contentAll  padding-lr-30  top-80  padding-bottom-80 ">
			<div class=" bg-white padding content-box padding">
				<div class="flex align-center solid-bottom padding-bottom-10">
					<div class=" tabItem flex  align-center justify-center margin-right-100" :class="tabIndex==1?'active':'normal'"
						@click="tabChange(1)">
						<img src="../../assets/image/icon/wechat2.png" class="block-40 margin-right-5">
						<span class="text-666 point fs9">微信支付</span>
					</div>
					<div class=" tabItem flex  align-center justify-center" :class="tabIndex==2?'active':'normal'"
						@click="tabChange(2)">
						<img src="../../assets/image/icon/ali2.png" class="block-40 margin-right-5">
						<span class="text-666 point fs9">支付宝支付</span>
					</div>
				</div>
				<!-- 支付二维码 -->
				<div class="flex align-center justify-center margin-tb-100">
					<div class="">
						<p class="fs24 text-333">微信支付</p>
						<p class="fs16 text-666 margin-top-10">打开微信扫一扫，扫描下方二维码完成支付</p>
						<div class="qr_code  block-320 flex align-center justify-center margin-top-30">
							<img src="../../assets/image/center/left1.png" class="block-274">
						</div>
					</div>
				</div>
			</div>
			
		</div>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		components: {
			bottom,
			top
		},
		data(){
			return{
				tabIndex:1
			}
		},
		methods:{
			// tab切换
			tabChange(index) {
				this.tabIndex = index
			}
		}
	}
</script>

<style  lang="scss">
html, body{
	background: #F6F7F9 !important;
	.content{
		width: 100%;
		background: #FFFFFF;
	}
}
.contentAll{
	width: 60%;
}
.active {
	position: relative;
	color: #333;
	font-weight: bold;
}

.active::after {
	position: absolute;
	content: '';
	bottom: -10px;
	left: 0;
	right: 0;
	width: 80%;
	margin: auto;
	background-color: #E54F42;
	height: 3px;
	border-radius: 2px;
}
.qr_code{
	border: 1px solid #CCCCCC;
}
</style>